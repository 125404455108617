.account__header__bio,
.status__content__text,
.e-content,
.edit-indicator__content,
.reply-indicator__content {
  pre,
  blockquote {
    margin-bottom: 20px;
    white-space: pre-wrap;
    unicode-bidi: plaintext;

    &:last-child {
      margin-bottom: 0;
    }
  }

  code,
  pre {
    font-family: monospace;
  }

  code {
    margin: 0 8px;
  }

  pre {
    padding: 4px 8px;

    span.ellipsis::after {
      content: '';
    }

    span.invisible {
      display: inline;
      font-size: inherit;
      line-height: inherit;
      width: inherit;
      height: inherit;
      position: static;
    }
  }

  blockquote {
    padding-inline-start: 10px;
    border-inline-start: 3px solid $darker-text-color;
    color: $darker-text-color;
    white-space: normal;

    p:last-child {
      margin-bottom: 0;
    }
  }

  & > ul,
  & > ol {
    margin-bottom: 20px;
  }

  b,
  strong {
    font-weight: 700;
  }

  em,
  i {
    font-style: italic;
  }

  ul,
  ol {
    margin-inline-start: 2em;

    p {
      margin: 0;
    }
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  sup {
    vertical-align: super;
  }

  .emojione {
    transition: transform 0.2s ease;

    &:hover {
      transform: scale(1.2);
    }
  }
}

.status__content__text {
  a.kmy-dangerous-link {
    color: red !important;
  }
}
